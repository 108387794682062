import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LeadForm from '../../components/formik/forms/lead/LeadForm'
import Layout from '../../components/Layout'
import { useInternalQuery } from '../../hooks/useQuery'
import { Lead } from '../../types/lead'
import { getReceiverLeadQuery, receiverLeadQueryProperties } from './query'
import { useAuth } from '../../hooks'
import { useSnackbar } from '../../hooks/useSnackbar'
import { removeTypesFromObject } from '../../helpers/objectManipulation'

type Props = {}

const leadMutation = gql`
  mutation updateOneLead($set: LeadUpdateInput!, $query: LeadQueryInput) {
    updateOneLead(set: $set, query: $query) {
      ${receiverLeadQueryProperties}
    }
  }
`

const EditLead: React.FC<Props> = () => {
  const { identifier } = useParams()
  const { user } = useAuth()
  const { t } = useTranslation()
  const { sendSnackbarMessage } = useSnackbar()
  const navigate = useNavigate()
  const [updateLead] = useMutation(leadMutation)
  const { data, loading } = useInternalQuery<Lead>(
    getReceiverLeadQuery({
      query: {
        _id: identifier,
      },
    }),
    'lead',
  )
  const receiver: boolean = user.userId === data?.receiver

  const handleSubmit = async (values: Partial<any>, actions: any) => {
    const {
      cancelDescription,
      closingDate,
      probability,
      sellingCoverage,
      findersFee,
      status: { value },
      // eslint-disable-next-line
      ...others
    } = values
    const formattedDate = new Date(closingDate).toISOString()

    const { ...body } = {
      cancelDescription,
      probability,
      findersFee: receiver && findersFee,
      sellingCoverage,
      closingDate: formattedDate,
      status: value || 'open',
    }
    try {
      await updateLead({
        variables: {
          set: {
            ...removeTypesFromObject(body, '__typename'),
          },
          // eslint-disable-next-line
          query: { receiver: user.userId, _id: values._id },
        },
      })
      sendSnackbarMessage(t('leadSendSuccess'))
      navigate('/leads', { replace: true })
    } catch (e: any) {
      // eslint-disable-next-line
      sendSnackbarMessage(t('leadSendFailure'), { messageType: 'error' })
    } finally {
      actions.setSubmitting(false)
    }
  }

  // return if loading
  return (
    <Layout loading={loading}>
      <LeadForm
        onSubmit={handleSubmit}
        initialValues={data}
        receiver={receiver}
      />
    </Layout>
  )
}

export default EditLead
