import { gql } from '@apollo/client'
import { objectToGQLSyntax } from '../../utils'

export const getOwnProfile = () => gql`
query($userId: ObjectId) {
  member (query: { userId: $userId }) {
      ${memberQueryProperties}
   }
  }
`

export const getProfileQuery = (args: any) => gql`
query {
  member (${objectToGQLSyntax(args)}) {
      ${memberQueryProperties}
   }
  }
`

export const getProfilesQuery = (args: any) => gql`
query {
  members(${objectToGQLSyntax(args)}) {
      ${memberQueryProperties}
   }
  }
`

export const searchMemberQueryProperties = `
    _id
    userId
    firstName
    lastName
    serviceTopic
    boostGroup {
      name
      city
    }
    boostTeam {
       _id 
       name
    }
    `

export const memberQueryProperties = `
    _id
    userId
    aboutMe
    boostTeam {
       _id 
       name
    }
    boostGroup {
        _id
    }
    crmId
    bookMeetingURL
   detailedServicesPitch
      emails {
        address
        primary
      }
    facebook
    locations {
      address
      city
      zipcode
      country
      primary
    }
    cases {
     headline
     body
     type
     link
    }
    companies {
      companyId
      logoURL
      companyAccountNumber
      name
      sicCode
      primary
    }
    boostGroup {
      name
      city
    }
    serviceProducts {
      description
      headline
      productImageURL
    }
    firstName
    lastName
    linkedin
    phoneNumber
    phrase
    profileBackgroundPictureURL
    profilePictureURL
    jobTitle
    serviceTopic
    twitter
    uniqueURLid
    updatedAt
    membership {
      expiresAt
    }

`

export const updateMemberQueryProperties = `
    _id
    userId
    aboutMe
    crmId
    bookMeetingURL
   detailedServicesPitch
      emails { address
        primary
      }
    facebook
    locations {
      address
      city
      zipcode
      country
      primary
    }
    cases {
     headline
     body
     type
     link
    }
    companies {
      companyId
      logoURL
      name
      sicCode
      primary
    }
    serviceProducts {
      description
      headline
      productImageURL
    }
    firstName
    lastName
    linkedin
    phoneNumber
    phrase
    profileBackgroundPictureURL
    profilePictureURL
    jobTitle
    serviceTopic
    twitter
    uniqueURLid
    updatedAt
`
