import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'
import { Box, Flex, Spinner } from '@chakra-ui/react'
import InputRightElement from '../../Inputs/InputRightThingy'
import { FormikInput, SubmitButton } from '../../Inputs'
import { formikErrorLocalisations } from '../../../../lib/i18n'
import FAIcon from '../../../FAIcon'
import { primaryColor } from '../../../../theme/styles'
import { Lead } from '../../../../types/lead'
import { searchMemberQueryProperties } from '../../../../pages/Profile/query'
import MemberList from '../../../MemberList'
import useTeams from '../../../../hooks/useTeams'
import convertArrayToSelectOptions from '../../../../helpers/convertArrayToSelectOptions'
import {
  formReceiverValidationSchema,
  formValidationSchema,
} from './validationSchema'
import ReceiverLeadForm from './ReceiverLeadForm'

formikErrorLocalisations()

interface Props {
  initialValues: any // Partial<Lead>
  onSubmit: (values: Partial<Lead>, actions: any, leadReceiver: string) => void
  receiverFields: boolean
}

const LeadInfoForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  // eslint-disable-next-line
  receiverFields,
}) => {
  const { t } = useTranslation()
  const [receiver, setReceiver] = useState<string>('')
  const [receiverId, setReceiverId] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [hideMemberList, setHideMemberList] = useState<boolean>(false)
  const [searchWord] = useDebounce(receiver, 1000)
  const { boostTeams } = useTeams()
  const query = gql`
    query search($input: String!) {
      search(input: $input) {
        ${searchMemberQueryProperties}
      }
    }
  `
  const { data, refetch } = useQuery(query, {
    variables: {
      input: searchWord,
    },
  })
  const results = data?.search || []

  useEffect(() => {
    getResults()
  }, [searchWord])

  const getResults = async () => {
    if (refetch && receiver) {
      await refetch({
        input: searchWord,
      })
      setLoading(false)
    }
    setLoading(false)
  }

  const setLeadReceiver = (receiverName: string, userId: string) => {
    setReceiver(receiverName)
    setReceiverId(userId)
    setHideMemberList(true)
  }

  const onChangeSearch = (text: string) => {
    setHideMemberList(false)
    setReceiver(text)
    setLoading(true)
  }

  return (
    <Box>
      {results.length > 0 && searchWord !== '' && !hideMemberList ? (
        <MemberList
          data={results}
          onClick={(name: string, userId: string) =>
            setLeadReceiver(name, userId)
          }
        />
      ) : (
        <Formik
          onSubmit={(values, actions) => {
            onSubmit(values, actions, receiverId)
          }}
          initialValues={initialValues}
          validationSchema={
            receiverFields ? formReceiverValidationSchema : formValidationSchema
          }
          validateOnChange={false}
        >
          <Form>
            <Box>
              {!receiverFields && (
                <FormikInput
                  name="receiver"
                  isDisabled={receiverFields}
                  inputProps={{
                    value: receiver,
                    onChange: (event) => onChangeSearch(event.target.value),
                  }}
                  placeholder={t('lead.receiver')}
                />
              )}
              {loading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  direction="row"
                >
                  <Spinner />
                </Flex>
              ) : null}
              <FormikInput
                name={`title`}
                isDisabled={receiverFields}
                placeholder={t('lead.heading')}
              />
              {receiverFields ? (
                <FormikInput
                  name={`team.name`}
                  isDisabled={receiverFields}
                  placeholder={initialValues.team.name}
                  type="select"
                  options={convertArrayToSelectOptions(
                    boostTeams ? boostTeams.teams : [],
                  )}
                />
              ) : (
                <FormikInput
                  name={`team.name`}
                  isDisabled={receiverFields}
                  placeholder={t('lead.team')}
                  type="select"
                  options={convertArrayToSelectOptions(
                    boostTeams ? boostTeams.teams : [],
                  )}
                />
              )}
              <FormikInput
                name={'customer'}
                isDisabled={receiverFields}
                placeholder={t('lead.customer')}
              />
              <FormikInput
                name={'customerContact'}
                isDisabled={receiverFields}
                placeholder={t('lead.customerDetails')}
              />
              <FormikInput
                name={'customerDescription'}
                placeholder={t('lead.customerDescription')}
                type="textarea"
              />
              {receiverFields ? (
                <ReceiverLeadForm initialValues={initialValues} />
              ) : (
                <FormikInput
                  name={'probability'}
                  placeholder={'0'}
                  label={t('lead.probability')}
                >
                  <InputRightElement>
                    <FAIcon icon="percentage" />
                  </InputRightElement>
                </FormikInput>
              )}
            </Box>
            <Box mt={9}>
              <SubmitButton
                margin={0}
                width={'100%'}
                bg={primaryColor}
                type="submit"
              >
                {receiverFields ? 'Tallenna' : 'Lähetä'}
              </SubmitButton>
            </Box>
          </Form>
        </Formik>
      )}
    </Box>
  )
}

export default LeadInfoForm
